export function getSegmentedRoute(path: string) {
    return "/:communityAddress" + path;
}

export function getSegmentedCommunityAddress(): string {
    const segments = window.location.pathname.split("/").filter(s => s);
    return segments[0];
}

export function getSegmentedPath(path: string) {
    return "/" + getSegmentedCommunityAddress() + path;
}

export function getUnsegmentedPath() {
    return "/" + window.location.pathname.split("/").slice(2).join("/");
}

export function segmentedPathStartsWith(path: string) {
    return getUnsegmentedPath().startsWith(path);
}
