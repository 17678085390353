// PrefixLink.tsx
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { getSegmentedCommunityAddress } from '../../_utils/segmented-community-util';

interface WithPrefixProps extends LinkProps {
    prefix: string;
}

const withPrefix = (prefix: string) => <P extends LinkProps>(WrappedComponent: React.ComponentType<P>) => {
    const WithPrefix: React.FC<P> = (props) => {
        const { to, ...restProps } = props;

        const newTo = `${prefix}${to}`;

        return <WrappedComponent {...restProps as P} to={newTo} />;
    };

    return WithPrefix;
};

const PrefixedLink = withPrefix(`/${getSegmentedCommunityAddress()}`)(Link);

export default PrefixedLink;
